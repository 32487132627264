import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebook, faTwitter, faInstagram, faYoutube, faGratipay } from '@fortawesome/free-brands-svg-icons';
import { faEuroSign, faMoneyBillWave, faClock, faMale, faFemale, faCity, faFlag, faList, faCalendarTimes, faLayerGroup, faClipboardList, faWheelchair, faUniversalAccess, faCar, faRulerCombined, faTools, faSyringe, faMobileAlt, faFirstAid, faChalkboardTeacher, faHotTub, faBeer, faUtensils, faTshirt, faUsersCog, faUserTie, faChair, faQuestionCircle, faTint, faBolt, faShower, faToilet, faParking, faUserLock, faTimes, faCaretRight,faMinus,faPlus,faCaretDown,faMouse, faFeatherAlt,faPhone,faArrowCircleDown,faBars,faSearch,faEye,faAddressBook,faLocationArrow,faCalendarCheck,faTags,faUsers,faComment, faCaretSquareDown, faNewspaper, faHandsHelping, faHandHoldingHeart, faFolderOpen, faArrowAltCircleRight, faCalendarAlt, faPhotoVideo, faEnvelope, faBookOpen, faShoePrints, faTrophy, faCompactDisc, faCog, faMicrophone,faHome, faAngleDown, faAngleRight, faMapMarkerAlt, faRss } from '@fortawesome/free-solid-svg-icons';

export default {
  containerInit() {
    // this is the id of the form


    // common code for all containers
    // add the imported icons to the library
  library.add(
    faMapMarkerAlt,
    faCaretRight,
    faMinus,
    faPlus,
    faCaretDown,
    faFacebook,
    faTwitter,
    faInstagram,
    faComment,
    faUsers,
    faArrowCircleDown,
    faBars,
    faSearch,
    faPhone,
    faEye,
    faCalendarCheck,
    faTags,
    faAddressBook,
    faLocationArrow,
    faCaretSquareDown,
    faNewspaper,
    faYoutube,
    faGratipay,
    faHandHoldingHeart,
    faHandsHelping,
    faFolderOpen,
    faArrowAltCircleRight,
    faCalendarAlt,
    faPhotoVideo,
    faFeatherAlt,
    faEnvelope,
    faShoePrints,
    faMouse,
    faCompactDisc,
    faCog,
    faTrophy,
    faBookOpen,
    faMicrophone,
    faHome,
    faAngleDown,
    faAngleRight,
    faTimes,
    faRss,
    faTint,
    faBolt,
    faShower,
    faToilet,
    faParking,
    faUserLock,
    faQuestionCircle,
    faCalendarTimes,
    faLayerGroup,
    faClipboardList,
    faWheelchair,
    faUniversalAccess,
    faCar,
    faRulerCombined,
    faTools,
    faSyringe,
    faMobileAlt,
    faFirstAid,
    faChalkboardTeacher,
    faHotTub,
    faBeer,
    faUtensils,
    faTshirt,
    faUsersCog,
    faUserTie,
    faChair,
    faList,
    faCity,
    faFlag,
    faMale,
    faFemale,
    faMoneyBillWave,
    faClock,
    faEuroSign
  );
  },
  init() {
    // JavaScript to be fired on all pages
    this.containerInit();
    // tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
    dom.watch();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    const searchForm = document.querySelector('#search-form');
    $('#search-icon').on('click', function () {
      const icon = this.querySelector('svg');
      const newIco = document.createElement('i');

      if (icon.classList.contains('fa-magnifying-glass')) {
        newIco.classList.add('fas');
        newIco.classList.add('fa-times');
        this.replaceChild(newIco, icon);
        searchForm.classList.remove('d-none');
        searchForm.classList.add('d-block');
      } else {
        newIco.classList.add('fas');
        newIco.classList.add('fa-search');
        this.replaceChild(newIco, icon);
        searchForm.classList.add('d-none');
        searchForm.classList.remove('d-block');
      }
    });
    $('#btn-track').on('click', function () {
      // Incrémenter le compteur de clics
      window.buttonClicks = window.buttonClicks || 0;
      window.buttonClicks++;
      var button = document.getElementById('btn-track');
      // Afficher le nombre de clics dans la console du navigateur
      button.innerHTML +=
        '<span class="badge badge-light">' + window.buttonClicks + '</span>';
    });

    // eslint-disable-next-line
    window.addEventListener("scroll", () => {
      const header = document.getElementById('main-header');
      const menu = document.getElementById('menu-primary');
      const topDistance = header.getBoundingClientRect().bottom;
      if (topDistance <= 0) {
        if (document.body.classList.contains('logged-in')) {
          menu.classList.add('fixed-top');
          menu.classList.add('mt-4');
        } else {
          menu.classList.add('fixed-top');
        }
      } else {
        menu.classList.remove('fixed-top');
      }
    });
    window.addEventListener('load', () => {
      const styles = document.querySelectorAll('link[media="print"]');
      styles.forEach((style) => {
        style.setAttribute('media', 'all');
      });
    });
  },
};
